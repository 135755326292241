import React, { createContext, useState, useEffect, useContext } from 'react';
import { useEditDisplayStatusMutation, useGetSmsRecordsQuery } from '../../features/sms/smsSlice';
import { useEditBookingMutation } from '../../features/bookings/bookingsApiSlice';

const SMSRecordContext = createContext();

export const useRecords = () => useContext(SMSRecordContext);

export const RecordsProvider = ({ children }) => {
  const { data, isLoading } = useGetSmsRecordsQuery();

  const [records, setRecords] = useState([]);

  const [editDisplayStatus] = useEditDisplayStatusMutation();

  const changeDisplayStatus = async (id) => {
    if (id) {
      try {
        const response = await editDisplayStatus(id).unwrap();
        console.log('Status edited?', response);
      } catch (error) {
        console.error('Failed', error);
      }
    }
  };

  const updateRecord = (updatedRecord) => {
    setRecords((prevRecords) => {
      const recordExists = prevRecords.some(
        (record) => record.id === updatedRecord.id
      );

      if (recordExists) {
        // Update the existing record
        return prevRecords.map((record) =>
          record.id === updatedRecord.id ? updatedRecord : record
        );
      } else {
        // Add the new record to the array
        return [updatedRecord, ...prevRecords];
      }
    });
  };

  const getNumberOfNotifications = () => {
    if (records) {
      if (records.length > 99) {
        return '99+';
      } else {
        return records.length;
      }
    }
  };

  const removeRecordById =async (id) => {
    await changeDisplayStatus(id);
    setRecords((prevRecords) =>
      prevRecords.filter((record) => record.id !== id)
    );
  };

  useEffect(() => {
    console.log('SMSRECORDS', data);
    if (data) {
      setRecords((prevRecords) => [...prevRecords, ...data]);
    }
  }, [data]);

  useEffect(() => {
    console.log('SMSRECORDS', records);
  }, [records]);

  return (
    <SMSRecordContext.Provider
      value={{
        records,
        updateRecord,
        getNumberOfNotifications,
        removeRecordById,
      }}
    >
      {children}
    </SMSRecordContext.Provider>
  );
};
