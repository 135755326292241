import React, { useState } from 'react';
import './ThankYouContent.css';

const ThankYouContent = ({ response }) => {
  let spanContent = 'Hvala što ste potvrdili vožnju.';
  let divClassName="pngContainer"

  console.log(response)

  if (response.data != undefined) {
    if (response.data.Message != 'Reservation is already confirmed!') {
      console.log('dasdasdasdsa');
      if (response.data.Message == 'Token has expired!') {
        spanContent = 'Zakasnili ste! Vaša vožnja je prošla.';
        divClassName="invalidPngContainer"
      } else {
        spanContent = 'Došlo je do greske!';
        divClassName="invalidPngContainer"

      }
    }
  }
  else if(response.error){
    spanContent="Došlo je do greške. Pokušajte kasnije."
    divClassName="invalidPngContainer"
  }

  return (
    <div className="thankYouContentMainContainer">
      <div className={divClassName}></div>
      <div className="thankYou-text-container">
        <span>{spanContent}</span>
        {divClassName == 'pngContainer' && (
          <span>Vaša rezervacija je sada završena.</span>
        )}
      </div>
    </div>
  );
};

export default ThankYouContent;
