import React, { useEffect, useState } from 'react';
import { Button, Drawer } from '@mui/material';
import DrawerContent from './DrawerContent';

//TODO SHOULD CHANGE FILE NAME
const DrawerBar = ({open,onClose}) => {
  // const [open, setOpen] = React.useState(false);
  console.log(open)

  return (
    <>
    <div>

      <Drawer className='sms-notification-drawer' style={{zIndex:"100 !important"}} anchor='right' open={open} onClose={onClose} >
        <DrawerContent onClcik={onClose} />
      </Drawer>
    </div>
    </>
  );
};

export default DrawerBar;
