import { Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import { max } from 'date-fns';
//TODO SHOULD CHANGE FILE NAME
const CustomLinearProgress = ({ progressValue }) => {
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 12,
    // width:'100%',
    borderBottomLeftRadius:8,
    borderBottomRightRadius:8,


    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: '#e3e3e3',
      ...theme.applyStyles('dark', {
        backgroundColor: theme.palette.grey[800],
      }),
    },
    [`& .${linearProgressClasses.bar}`]: {
      backgroundColor: '#F5F5DC',
      ...theme.applyStyles('dark', {
        backgroundColor: '#308fe8',
      }),
    },
  }));
  return (
    <>
      <div>
        <span className="progress-bar-percentage-container">
          {progressValue.toFixed(2)}%
        </span>
        <BorderLinearProgress variant="determinate" value={progressValue} />
      </div>
    </>
  );
};

export default CustomLinearProgress;
