import { apiSlice } from '../../app/api/apiSlice';

export const stopSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    sendSMS: builder.mutation({
      query: (body) => ({
        url: `SMS/NotifyUsers`,
        method: 'POST',
        body,
      }),
    }),

    confirmReservation: builder.mutation({
      query: (token) => ({
        url: `SMS/ConfirmReservation`,
        method: 'PUT',
        params: { token },
      }),
    }),

    editDisplayStatus: builder.mutation({
      query: (id) => ({
        url: `SMS/EditDisplayStatus`,
        method: 'PUT',
        params: { id },
      }),
    }),

    getSmsRecords: builder.query({
      query: () => ({
        url: `SMS/GetRecords`,
        method: "GET",
      }),
    }),
  }),
});

export const { useSendSMSMutation, useConfirmReservationMutation,useGetSmsRecordsQuery,useEditDisplayStatusMutation } = stopSlice;
