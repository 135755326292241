import React, { useEffect, useState } from 'react';
import SMSRecord from '../Sms/SMSRecord';
import { useRecords } from '../../store/context/SMSRecordContext';
import SMSStatuses from '../Sms/SmsStatuses';
import CustomDialog from '../Dialog/CustomDialog';

//TODO SHOULD CHANGE FILE NAME
const DrawerContent = ({ onClcik }) => {
  const { records,removeRecordById } = useRecords();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState();

  const handleNotificationClick = (record) => {
    // console.log(record);
    setData(record);
    setOpen(true);
  };

  useEffect(()=>{
    console.log(data);
  },[data])

  return (
    <>
      <div
        style={{ width: '300px', padding: '5px',zIndex:"100" }}
        role="presentation"
        
        // onClick={onClcik}
      >
        <h2 style={{ textAlign: 'center' }}>Notifications</h2>
        {records.length === 0 ? (
          <p>No records found.</p>
        ) : (
          records.map((record) => (
            <div key={record.id}>
              {' '}
              {/* Assuming `record` has a unique `id` property */}
              <SMSRecord
                record={record}
                onClick={() => handleNotificationClick(record)}
                onRemoveClick={async ()=>await removeRecordById(record.id)}
              />
            </div>
          ))
        )}
        {open && data && (
          <CustomDialog
            onOpen={open}
            onClose={() => {
              setOpen(false);
            }}
            customMargin="0px"
          >
            <SMSStatuses responses={data.smSs} />
          </CustomDialog>
        )}
      </div>
    </>
  );
};

export default DrawerContent;
