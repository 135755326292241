import { Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { styled } from '@mui/material/styles';
import LinearProgress from './CustomLinearProgress';
import CloseIcon from '@mui/icons-material/Close';
//TODO SHOULD CHANGE FILE NAME
const SMSRecord = ({ record, onClick,onRemoveClick }) => {
  let progress = (record.smSs.length / record.numberOfSMSs) * 100;
  console.log(progress);
  const [failedCount, setFailedCount] = useState(0);

  const date = new Date(record.sesionSterted);

  const dateFormatter = new Intl.DateTimeFormat('sr-RS', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });

  // Format the time
  const timeFormatter = new Intl.DateTimeFormat('sr-RS', {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false, // Use 24-hour format
  });
  //   smsData.status=="Sent" || smsData.status== "Delivered"
  const formattedDate = dateFormatter.format(date);
  const formattedTime = timeFormatter.format(date);

  useEffect(() => {
    if (record) {
      const total = record.smSs.length;
      const failures = record.smSs.filter(
        (response) => response.status !== 'Sent'
      ).length;

      setFailedCount(failures);
    }
  }, [record]);

  return (
    <>
      <div className="sms-record-main-container" >
        <div className="sms-record-title-exit">
          <span className="sms-record-title">SMS Sending Report</span>
          <CloseIcon onClick={onRemoveClick} />
        </div>
        <div className='sms-record-conntent-container' onClick={onClick}>
          <div className="date-time-sms-record-container sms-record-date-time">
            <span>{formattedDate}</span>
            <span>{formattedTime}</span>
          </div>
          <span className="date-time-sms-record-container">
            {failedCount == 0 ? (
              <span
                style={{ color: 'green' }}
                className="sms-record-number-of-sent-messages"
              >
                success
              </span>
            ) : (
              <div
                className="date-time-sms-record-container failed-sms-record-status"
              >
                <span>{failedCount} failed</span>
              </div>
            )}
            <span className='record-done-sms-sending'>
            {record.smSs.length}/{record.numberOfSMSs}
            </span>
          </span>
        </div>
        <LinearProgress progressValue={progress} />
      </div>
    </>
  );
};

export default SMSRecord;
